import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import PostContent from "../../components/PostContent";

const PostPage = (props) => {
  const post = props.data.microcmsPost;
  return (
    <Layout>
      <title>{post.title}</title>
      <meta name="description" content={`${post.title}`} />
      <PostContent post={post} />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    microcmsPost(id: { eq: $id }) {
      slug
      title
      content
      publishedAt(formatString: "YYYY.DD.MM hh:mm")
      category {
        slug
        name
      }
      thumbnail {
        url
      }
    }
  }
`;

export default PostPage;
